import { Button, Grid } from "@mui/material";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import AppCheckboxField from "../../app/components/AppCheckboxField";

export function Feedback(props: any) {
    const {
        formField: {
            ReviewerConfirmation,
            ReviewerComments,
            PractionerComments
        }
    } = props;

    const { control, handleSubmit } = useForm();

    async function _submitForm(values: any, actions: any) {
        actions.setSubmitting(false);
    }

    return <>
        <form onSubmit={handleSubmit(_submitForm)}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={4}>
                    <AppCheckboxField control={control} name={ReviewerConfirmation.name}
                        label={ReviewerConfirmation.label} options={[ReviewerConfirmation.label]} />
                </Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={12}>
                    <AppTextareaInput name={ReviewerComments.name} label={ReviewerComments.label}
                        placeholder={ReviewerComments.label} disabled />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput name={PractionerComments.name} label={PractionerComments.label}
                        placeholder={PractionerComments.label} disabled />
                </Grid>

                <Grid item xs={9}></Grid>

                <Grid item xs={2}>
                    <Button variant='contained' type='submit' sx={{ mt: 3, ml: 1 }}>
                        Save as a Draft
                    </Button>
                </Grid>

                <Grid item xs={1}>
                    <Button variant='contained' type='submit' sx={{ mt: 3, ml: 1 }}>
                        Submit
                    </Button>
                </Grid>

            </Grid>
        </form>
    </>
}