import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getCanEnrolAsync } from '../slices/pcsSlice';

export const useCanEnrol = (reenrol: boolean) => {
    const dispatch = useAppDispatch();
    const { canEnrol, canEnrolStatus } = useAppSelector(state => state.pcs);

    useEffect(() => {
        if (reenrol === false && canEnrol === null && canEnrolStatus === ApiRequestStatus.Idle) {
            dispatch(getCanEnrolAsync());
        }
    }, [canEnrol, canEnrolStatus, dispatch]);

    return { canEnrolStatus };
};
