import { Grid, Typography } from "@mui/material";
import React from "react";
import { CurrencyCodeIconEnum } from "../../app/enums/currencyCodeIconEnum";
import PaymentProductJsonDto from "../../app/models/affiliateOnboarding/paymentProductJsonDto";
import { PaymentDto } from "../../app/models/payment/paymentDto";
import { PaymentProductDto } from "../../app/models/payment/paymentProductDto";
import { useAppSelector } from "../../app/store/configureStore";
import { convertToPrecesion } from "../../app/utils/util";

interface Props {
    paymentDetails: PaymentDto | null;
}

interface PaymentProductProps {
    year: string;
    amount: number | null;
}

export default function PcsSubscriptionFeesDue(props: Props) {
    const { paymentDetails } = props;
    const { products } = useAppSelector(state => state.ReferenceData);

    const paymentProducts = () => {
        let paymentProductArray: PaymentProductProps[] = [];

        paymentDetails?.paymentProducts.map((paymentProduct: PaymentProductDto) => {
            if (paymentProduct.jsonValues !== null) {
                let paymentProductJsonObject = JSON.parse(paymentProduct.jsonValues) as PaymentProductJsonDto;

                paymentProductJsonObject.Year && paymentProductArray.push({
                    year: paymentProductJsonObject.Year + ' ' + products?.find(x => x.id === paymentProduct.productId)?.name,
                    amount: paymentProduct.cost
                });
            }
        });

        return paymentProductArray;
    }

    return <>
        <Grid item xs={12} sx={{ paddingTop: '1% !important' }}>
            <Typography variant='subtitle1' sx={{ fontWeight: "500" }}>Fees Due</Typography>
        </Grid>

        <Grid container rowSpacing={4}>

            <Grid item sm={12}></Grid>

            {
                paymentProducts().map((item: PaymentProductProps) => (
                    <React.Fragment key={item.year}>

                        <Grid item xs={5} md={6} sm={5}>
                            {item.year}
                        </Grid>

                        <Grid item xs={0} md={1} sm={1}></Grid>

                        <Grid item xs={7} md={5} sm={6}>
                            {CurrencyCodeIconEnum.EUR} {convertToPrecesion(item.amount?.toString())}
                        </Grid>

                    </React.Fragment>
                ))
            }

            <>
                <Grid item xs={12}></Grid>

                <Grid item xs={5} md={6} sm={5}>
                    <b>Total Due</b>
                </Grid>

                <Grid item xs={0} md={1} sm={1}></Grid>

                <Grid item xs={7} md={5} sm={6}>
                    {CurrencyCodeIconEnum.EUR} {paymentDetails?.amount}
                </Grid>
            </>

        </Grid>
    </>
}