import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { SummaryDto } from "../models/summary/summaryDto";
import { PCSDetailsDto } from "../models/pcsEnrolment/pcsDetailsDto";
import { UpdateEducationCommand } from "../models/person/updateEducationCommand";
import { PCSSummaryDto } from "../models/pcs/pcsSummaryDto";

interface PcsState {
    currentYear: number | null;
    summaryData: SummaryDto[][] | null;
    pcsSummaryDto: PCSSummaryDto | null;
    registrationDetails: PCSDetailsDto | null;
    canEnrol: boolean | null;

    registerStateStatus: ApiRequestStatus;
    saveRegistrationDetailsStatus: ApiRequestStatus;
    currentYearStatus: ApiRequestStatus;
    getCreditSummaryStatus: ApiRequestStatus;
    updateEducationalDetailsStatus: ApiRequestStatus;
    canEnrolStatus: ApiRequestStatus;
}

const initialState: PcsState = {
    currentYear: null,
    summaryData: null,
    pcsSummaryDto: null,
    registrationDetails: null,
    canEnrol: null,
    registerStateStatus: ApiRequestStatus.Idle,
    saveRegistrationDetailsStatus: ApiRequestStatus.Idle,
    currentYearStatus: ApiRequestStatus.Idle,
    getCreditSummaryStatus: ApiRequestStatus.Idle,
    updateEducationalDetailsStatus: ApiRequestStatus.Idle,
    canEnrolStatus: ApiRequestStatus.Idle
}

export const getPcsCurrentYearAsync = createAsyncThunk<number>(
    'pcsSlice/getPcsCurrentYearAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Pcs.getCurrentYearAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getRegistrationDetailsAsync = createAsyncThunk<PCSDetailsDto>(
    'pcsSlice/getRegistrationDetailsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Pcs.getRegistrationDetailsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCanEnrolAsync = createAsyncThunk<boolean>(
    'pcsSlice/getCanEnrolAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Pcs.getCanEnrolAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCreditSummaryAsync = createAsyncThunk<PCSSummaryDto>(
    'pcsSlice/getCreditSummaryAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Pcs.getCreditSummaryAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveRegistrationDetailsAsync = createAsyncThunk<void, PCSDetailsDto>(
    'pcsSlice/saveRegistrationDetailsAsync',
    async (registrationDetails, thunkAPI) => {
        try {
            return agent.Pcs.saveRegistrationDetailsAsync(registrationDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateEducationAsync = createAsyncThunk<void, UpdateEducationCommand>(
    'pcsSlice/updateEducationAsync',
    async (educationalDetails, thunkAPI) => {
        try {
            return agent.Pcs.updateEducationAsync(educationalDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const pcsSlice = createSlice({
    name: 'pcsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getPcsCurrentYearAsync.pending, (state) => {
            state.currentYearStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPcsCurrentYearAsync.fulfilled, (state, action) => {
            state.currentYear = action.payload;
            state.currentYearStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getPcsCurrentYearAsync.rejected, (state) => {
            state.currentYearStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getRegistrationDetailsAsync.pending, (state) => {
            state.registerStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getRegistrationDetailsAsync.fulfilled, (state, action) => {
            state.registerStateStatus = ApiRequestStatus.Fulfilled;
            state.registrationDetails = action.payload;
        });
        builder.addCase(getRegistrationDetailsAsync.rejected, (state) => {
            state.registerStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCanEnrolAsync.pending, (state) => {
            state.canEnrolStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCanEnrolAsync.fulfilled, (state, action) => {
            state.canEnrolStatus = ApiRequestStatus.Fulfilled;
            state.canEnrol = action.payload;
        });
        builder.addCase(getCanEnrolAsync.rejected, (state) => {
            state.canEnrolStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCreditSummaryAsync.pending, (state) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCreditSummaryAsync.fulfilled, (state, action) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Fulfilled;
            state.summaryData = action.payload.summaryData;
            state.pcsSummaryDto = action.payload;
        });
        builder.addCase(getCreditSummaryAsync.rejected, (state) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveRegistrationDetailsAsync.pending, (state) => {
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveRegistrationDetailsAsync.fulfilled, (state, action) => {
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveRegistrationDetailsAsync.rejected, (state) => {
            state.saveRegistrationDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updateEducationAsync.pending, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateEducationAsync.fulfilled, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updateEducationAsync.rejected, (state) => {
            state.updateEducationalDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
