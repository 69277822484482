import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PersonalDetailsDto } from '../../../app/models/person/personalDetailsDto';
import { Country } from '../../../app/models/staticData/country';
import { Title } from '../../../app/models/staticData/title';
import { PaymentDto } from '../../../app/models/payment/paymentDto';
import { formatDateWithMonthName } from '../../../app/utils/util';
import { PaymentMethod } from '../../../app/models/staticData/paymentMethod';
import { Currency } from '../../../app/models/staticData/currency';
import { CurrencyCodeEnum } from '../../../app/enums/currencyCodeEnum';
import { PCSDetailsDto } from '../../../app/models/pcsEnrolment/pcsDetailsDto';
import { Scheme } from '../../../app/models/staticData/scheme';
import { useEffect, useState } from 'react';
import { SchemEnum } from '../../../app/enums/schemEnum';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        textAlign: 'left',
        height: 'auto',
        fontSize: 9,
        alignItems: 'center'
    },
    personalDetails: {
        width: '50%',
        fontStyle: 'bold',
        fontSize: 15,
        paddingBottom: '20px'
    },
    receiptDateHeader: {
        width: '18%',
        textAlign: 'left',
        paddingTop: '3px',
        fontStyle: 'bold',
        fontSize: 10,
        fontWeight: 700
    },
    receiptDateValue: {
        width: '32%',
        textAlign: 'left',
        paddingTop: '2px',
        paddingLeft: '3px'
    },
    referenceIdHeader: {
        width: '18%',
        textAlign: 'left',
        paddingTop: '3px',
        fontStyle: 'bold',
        fontSize: 10,
        fontWeight: 700
    },
    referenceIdValue: {
        width: '32%',
        textAlign: 'left',
        paddingTop: '3px',
        paddingLeft: '3px'
    },
    paymentMethodHeader: {
        width: '18%',
        textAlign: 'left',
        paddingTop: '3px',
        fontStyle: 'bold',
        fontSize: 10,
        fontWeight: 700
    },
    paymentMethodValue: {
        width: '32%',
        textAlign: 'left',
        paddingTop: '3px',
        paddingLeft: '3px'
    },
    postalAddressHeader: {
        width: '18%',
        textAlign: 'left',
        fontStyle: 'bold',
        fontSize: 10,
        fontWeight: 700,
        verticalAlign: 'super'
    },
    postalAddressValue: {
        width: '32%',
        textAlign: 'left'
    },
    postalCountryHeader: {
        width: '18%',
        textAlign: 'left',
        fontStyle: 'bold',
        fontSize: 10,
        fontWeight: 700
    },
    postalCountryValue: {
        width: '32%',
        textAlign: 'left'
    },
    logo: {
        width: 65,
        height: 94,
        alignContent: 'flex-start',
        marginBottom: '20px'
    },
    friendsOfRcsi: {
        width: '70%',
        fontSize: 25,
        fontWeight: 'extrabold',
        marginBottom: '40px'
    }
});

interface Props {
    paymentDetails: PaymentDto | null;
    titles: Title[] | null;
    countries: Country[] | null;
    currencies: Currency[] | null;
    personalDetails: PersonalDetailsDto | null;
    paymentMethods: PaymentMethod[] | null;
    registrationDetails: PCSDetailsDto | null;
    schemes: Scheme[] | null;
}

const ReceiptHeader = ({ paymentDetails, titles, countries, personalDetails, paymentMethods, currencies, registrationDetails, schemes }: Props) => {
    const [receiptLogo, setReceiptLogo] = useState<string>('');
    const [receiptLogoDesign, setReceiptLogoDesign] = useState({});

    useEffect(() => {
        if (registrationDetails !== null && schemes && schemes?.length > 0) {
            const schemeCode = schemes.find(x => x.id === registrationDetails.schemeId)?.code;
            let logoName = "";
            let logoDesign = {};

            if (schemeCode === SchemEnum.EmergencyMedicine) {
                logoName = "ICEMT-logo.jpg";
                logoDesign = { ...styles.logo, width: 65 };
            }
            else if (schemeCode === SchemEnum.Ophthalmology) {
                logoName = "Ophthalmology-logo.jpg";
                logoDesign = { ...styles.logo, width: 175 };
            }
            else if (schemeCode === SchemEnum.Radiology) {
                logoName = "Radiology-logo.png";
                logoDesign = { ...styles.logo, width: 175 };
            }
            else if (schemeCode === SchemEnum.SportsAndExerciseMedicine) {
                logoName = "FSEM-Trio-logo.jpg";
                logoDesign = { ...styles.logo, width: 400 };
            }
            else if (schemeCode === SchemEnum.Surgery) {
                logoName = "rcsi-logo-png.png";
                logoDesign = { ...styles.logo, width: 65 };
            }
            else {
                logoName = "rcsi-logo-png.png";
                logoDesign = { ...styles.logo, width: 65 };
            }

            setReceiptLogo(`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/${logoName}`);
            setReceiptLogoDesign(logoDesign);
        }
    }, [registrationDetails, schemes]);

    return (
        <>
            <View style={styles.row}>
                <Image style={receiptLogoDesign} src={receiptLogo} />
            </View>

            {paymentDetails?.currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.GBP)?.id &&
                <View style={styles.row}>
                    <Text style={styles.friendsOfRcsi}>
                        FRIENDS OF RCSI
                    </Text>
                </View>
            }

            <View style={styles.row}>
                <Text style={styles.personalDetails}>
                    {titles?.find(x => x.id === personalDetails?.titleId)?.value}{' '}{personalDetails?.firstName}{' '}{personalDetails?.lastName}
                </Text>
            </View>

            <View style={styles.row}>

                <Text style={styles.receiptDateHeader}>
                    Receipt Date
                </Text>

                <Text style={styles.receiptDateValue}>
                    {paymentDetails?.paymentDate && paymentDetails?.paymentDate !== null ? formatDateWithMonthName(new Date(paymentDetails?.paymentDate)) : ''}
                </Text>

                <Text style={styles.referenceIdHeader}>
                    Reference
                </Text>

                <Text style={styles.referenceIdValue}>
                    {paymentDetails?.reference}
                </Text>
            </View>

            <View style={styles.row}>

                <Text style={styles.postalCountryHeader}>
                    Postal country
                </Text>

                <Text style={styles.postalCountryValue}>
                    {countries?.find(x => x.id === personalDetails?.postalAddressCountryId)?.value}
                </Text>

                <Text style={styles.paymentMethodHeader}>
                    Payment Method
                </Text>

                <Text style={styles.paymentMethodValue}>
                    {paymentMethods?.find(x => x.id === paymentDetails?.paymentMethodId)?.name}
                </Text>
            </View>

            <View style={styles.row}>
                <Text style={styles.postalAddressHeader}>
                    Postal address
                </Text>

                <Text style={styles.postalAddressValue}>
                    {personalDetails?.postalAddress}
                </Text>
            </View>
        </>
    )
};

export default ReceiptHeader;