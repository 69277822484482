import { View, StyleSheet } from "@react-pdf/renderer";
import ReceiptTableHeader from "./ReceiptTableHeader";
import ReceiptTableRow from "./ReceiptTableRow";
import { SummaryDto } from "../../app/models/summary/summaryDto";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24
    }
});

interface Props {
    summaryData: SummaryDto[][] | null;
}

const ReceiptItemsTable = ({ summaryData }: Props) => {
    return (
        <View style={styles.tableContainer}>
            <ReceiptTableHeader />
            <ReceiptTableRow summaryData={summaryData} />
        </View>
    )
};

export default ReceiptItemsTable;