import { Box, Paper, Grid, Typography } from "@mui/material";

export default function PcsEnrolmentPaymentSucceed() {

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%' }}>
                    <Typography variant='h4'>Payment successful</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        Thank you for your payment. Please check your email confirming payment
                        and further instructions on how to download your receipt.
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
