import { Document, Page, StyleSheet, Font } from "@react-pdf/renderer";
import ReceiptTitle from "./ReceiptTitle";
import ReceiptItemsTable from "./ReceiptItemsTable";
import { SummaryDto } from "../../app/models/summary/summaryDto";
import ReceiptHeader from "./ReceiptHeader";
import ReceiptFooter from "./ReceiptFooter";
import ReceiptItems from "./ReceiptItems";
import { PCSDetailsDto } from "../../app/models/pcsEnrolment/pcsDetailsDto";
import { Scheme } from "../../app/models/staticData/scheme";
import { RegistrationType } from "../../app/models/staticData/registrationType";
import { useEffect, useState } from "react";

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Helvetica'
    }
});

interface Props {
    summaryData: SummaryDto[][] | null;
    registrationDetails: PCSDetailsDto | null;
    schemes: Scheme[] | null;
    registrationTypes: RegistrationType[] | null;
    personName: string;
}

export default function PdfDocument({ summaryData, registrationDetails, schemes, registrationTypes, personName }: Props) {
    const [isYearPartialyCompleted, setIsYearPartialyCompleted] = useState<boolean>(false);

    useEffect(() => {
        if (summaryData) {
            summaryData.map((item: SummaryDto[]) => {
                let isYearPartialyComplete = false;
                if (item[2].text.toLowerCase() === "true") {
                    if (isYearPartialyComplete === false) {
                        setIsYearPartialyCompleted(true);
                    }
                }
            });
        }
    }, [summaryData]);

    return (
        <>
            <Document>
                <Page style={styles.body}>
                    <ReceiptHeader registrationDetails={registrationDetails} schemes={schemes} />
                    <ReceiptTitle />
                    <ReceiptItems summaryData={summaryData} registrationDetails={registrationDetails} schemes={schemes}
                        registrationTypes={registrationTypes} personName={personName} />
                    <ReceiptItemsTable summaryData={summaryData} />
                    <ReceiptFooter isYearPartialyCompleted={isYearPartialyCompleted} />
                </Page>
            </Document >
        </>)
}
