import { Box, Grid, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import UnsavedChangesDialog from "../../../app/components/UnsavedChangesDialog";
import { useGetWorkpostList } from "../../../app/customHooks/useGetWorkpostList";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { WorkPostDto } from "../../../app/models/workpost/workPostDto";
import { deleteWorkpostAsync, getWorkpostListAsync } from "../../../app/slices/workpostSlice";
import { useAppSelector, useAppDispatch } from "../../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { formatDate, pcsFilter } from "../../../app/utils/util";
import AddWorkDetails from "./AddWorkDetails";
import { SpaName } from "../../../app/enums/spaName";
import registrationFormModel from "../../../app/models/pcsEnrolment/registrationFormModel";
import { useMsal } from "@azure/msal-react";
import { IdTokenClaims } from "../../../app/models/account/idTokenClaims";
import { useFormContext } from "react-hook-form";

interface Props {
  nextStepClick?: () => void;
  handleSave?: () => void;
  spaName: SpaName;
}

export default function WorkDetails({ nextStepClick, handleSave, spaName }: Props) {
  const {
    workDetailsFormField: {
      StartDate,
      PostLevel,
      Country,
      NameOfClinicalSupervisor,
      NameOfEmployer,
      OtherNameOfEmployer,
      JobTitle
    }
  } = registrationFormModel;

  const methods = useFormContext();
  const { reset } = methods;

  const { instance } = useMsal();
  const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

  const { countries, workPostLevels, hospitals } = useAppSelector(state => state.ReferenceData);
  const { workpostList, workpostListStatus, deleteWorkpostStatus } = useAppSelector(state => state.workpost);

  const [deleteLatestWorkpost, setDeleteLatestWorkpost] = useState<boolean>(false);
  const [latestWorkpost, setLatestWorkpost] = useState<WorkPostDto>();
  const [addWorkpost, setAddWorkpost] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useGetWorkpostList();

  useEffect(() => {
    if (workpostList && workpostList.length > 0) {
      setLatestWorkpost(workpostList?.slice().sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())[0]);
    }

    if (workpostList === null || workpostList?.length === 0) {
      setAddWorkpost(true);
    } else {
      setAddWorkpost(false);
    }
  }, [workpostList]);

  useEffect(() => {
    reset({
      CurrentUserRoles: userRoles
    });
  }, []);

  const deleteWorkpost = (workpostId: string | null | undefined) => {
    if (workpostId) {
      dispatch(deleteWorkpostAsync(workpostId)).then((response) => {
        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
          toast.success(MESSAGE_DETAILS_SAVED);
          setAddWorkpost(true);
          dispatch(getWorkpostListAsync());
        }
      });
    }
  }

  if (workpostListStatus === ApiRequestStatus.Pending || deleteWorkpostStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return <>
    {addWorkpost === false &&
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={4}>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Typography variant='h5'>Current Employment Details</Typography>
          </Grid>

          <Grid item xs={4}>
            <b>{StartDate.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {latestWorkpost?.startDate && formatDate(new Date(latestWorkpost?.startDate))}
          </Grid>

          <Grid item xs={3.5}></Grid>

          {userRoles && pcsFilter(userRoles) && <>
            <Grid item xs={4}>
              <b>{PostLevel.label.replace("*", "")}</b>
            </Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={4}>
              {workPostLevels?.find(x => x.id === latestWorkpost?.workPostLevelId)?.value}
            </Grid>

            <Grid item xs={3.5}></Grid>
          </>}

          <Grid item xs={4}>
            <b>{JobTitle.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {latestWorkpost?.jobTitle}
          </Grid>

          <Grid item xs={3.5}></Grid>

          <Grid item xs={4}>
            <b>{Country.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {countries && countries?.find(x => x.id === latestWorkpost?.countryId)?.value}
          </Grid>

          <Grid item xs={3.5}></Grid>

          {userRoles && pcsFilter(userRoles) &&
            <>
              <Grid item xs={4}>
                <b>{NameOfClinicalSupervisor.label.replace("*", "")}</b>
              </Grid>

              <Grid item xs={0.5}></Grid>

              <Grid item xs={4}>
                {latestWorkpost?.clinicalSupervisor}
              </Grid>

              <Grid item xs={3.5}></Grid>
            </>
          }

          {latestWorkpost?.employerId !== undefined && latestWorkpost?.employerId !== null &&
            latestWorkpost?.employerId !== "" && <>
              <Grid item xs={4}>
                <b>{NameOfEmployer.label.replace("*", "")}</b>
              </Grid>

              <Grid item xs={0.5}></Grid>

              <Grid item xs={4}>
                {hospitals?.find(x => x.id === latestWorkpost?.employerId)?.value}
              </Grid>

              <Grid item xs={3.5}></Grid>
            </>}

          {latestWorkpost?.employerOther !== undefined && latestWorkpost?.employerOther !== null &&
            latestWorkpost?.employerOther !== "" && <>
              <Grid item xs={4}>
                <b>{OtherNameOfEmployer.label.replace("*", "")}</b>
              </Grid>

              <Grid item xs={0.5}></Grid>

              <Grid item xs={4}>
                {latestWorkpost?.employerOther}
              </Grid>

              <Grid item xs={3.5}></Grid>
            </>}

          <Grid item xs={12}>
            <Typography variant='body1' sx={{ color: 'primary.main' }}>
              Please note, you cannot edit work details once submitted. You must delete and re-enter your current work details
            </Typography>
          </Grid>

          <Grid item lg={1.5} md={2} xs={12}>
            <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: '0' } }} variant="outlined"
              onClick={() => setDeleteLatestWorkpost(true)} >Delete</Button>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: '0' } }} variant="contained"
              onClick={() => setAddWorkpost(true)}>Add New Employment Details</Button>
          </Grid>

          <Grid item lg={5.5} md={6} xs={12}>
            {spaName === SpaName.PCS_ENROLMENT && <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: '0' } }} variant="contained"
              onClick={nextStepClick}>Current work details are correct</Button>}
          </Grid>

          {deleteLatestWorkpost &&
            <UnsavedChangesDialog showDialog={deleteLatestWorkpost}
              title={'Are you sure you want to delete the latest work details?'}
              subTitle={`You will loose the work details on clicking "YES".`}
              yesButtonClick={() => {
                deleteWorkpost(latestWorkpost?.id);
                setDeleteLatestWorkpost(false);
              }}
              noButtonClick={() => setDeleteLatestWorkpost(false)}
              showYesNoButtons={true} />}
        </Grid>
      </Box >
    }

    {addWorkpost === true && <AddWorkDetails backToWorkDetailsClick={() => setAddWorkpost(false)} handleSave={handleSave} />}
  </>
}
