import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { cpdValidationSchema } from "../../common/cpdValidationSchema";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { addCpdDetailsAsync } from "../../../../app/slices/creditsSlice";
import { CpdDto } from "../../models/cpdDto";
import cpdFormModel from "../../common/cpdFormModel";
import AppDatePicker from "../../../../app/components/AppDatePicker";
import AppCheckboxFieldWithLeftAlignedLabel from "../../../../app/components/AppCheckboxFieldWithLeftAlignedLabel";
import { useHistory } from "react-router-dom";
import { MapDomainsToCheckboxItems, MapCategoriesToAutocompleteItems, MapActivityTypesToAutocompleteItems }
    from "../../../../app/utils/convertStaticDataToDropdownItems";
import AppSlider from "../../../../app/components/AppSlider";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import AppTextareaInput from "../../../../app/components/AppTextareaInput";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../app/enums/userClaims";
import { useReferenceData } from "../../../../app/customHooks/useReferenceData";
import { AppPathEnum } from "../../../../app/enums/appPathEnum";
import { AutocompleteItem } from "../../../../app/models/common/autocompleteItem";
import AppAutocomplete from "../../../../app/components/AppAutocomplete";
import cpdCategoriesFormModel from "../../common/cpdCategoriesFormModel";

export default function AddCpdForm() {
    const {
        formField: {
            ActivityDate,
            Category,
            ActivityType,
            Domains,
            CreditsClaiming,
            Description,
            DevelopmentPurpose
        }
    } = cpdFormModel;

    const {
        formField: {
            AccreditedCeActivity,
            ProfessionalDevelopmentPlan,
            PracticeReview,
            WorkbasedLearning,
            OnTarget
        }
    } = cpdCategoriesFormModel;

    const { control, getValues, watch, handleSubmit } = useForm({
        mode: 'all',
        resolver: yupResolver(cpdValidationSchema)
    });

    const history = useHistory();

    async function submitForm(values: any) {
        dispatch(addCpdDetailsAsync(MapToCpdDetailsDto(values))).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                history.push(`${AppPathEnum.PCS_VIEW_CPD}/${response.payload}${window.location.search}`);
            }
        });
    }

    function MapToCpdDetailsDto(values: any) {
        const data: CpdDto = {
            activityDate: new Date(new Date(values.ActivityDate).toString().split('+')[0]),
            cpdCategoryId: values.Category,
            activityTypeId: values.ActivityType,
            goodPracticeDomainIds: values.Domains,
            credits: Number(values.CreditsClaiming),
            description: values.Description,
            purpose: values.DevelopmentPurpose,
            personId: personId
        }
        return data;
    }

    const dispatch = useAppDispatch();
    const { cpdCategories, activityTypes, goodPracticeDomains, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { addCpdStateStatus } = useAppSelector(state => state.credits);

    const [filteredActivityTypes, setFilteredActivityTypes] = useState<AutocompleteItem[]>([]);

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === Category.name && type === 'change') {
                const activityTypeList = activityTypes?.filter(x =>
                    x.cpdCategoryId === getValues(Category.name) && x.userVisible === true);
                setFilteredActivityTypes(MapActivityTypesToAutocompleteItems(activityTypeList ?? []));
            }
        });
    }, [Category.name, getValues, filteredActivityTypes, activityTypes, watch]);

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');

    useReferenceData();

    if (addCpdStateStatus === ApiRequestStatus.Pending || staticDataStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>
            <Grid container rowSpacing={1} sx={{ marginLeft: "2%", marginTop: "0%" }}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Add Record</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Activity Category Descriptions</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ width: '100%' }} component={Paper}>
            <Grid container sx={{ marginLeft: "2%", marginTop: "0%" }}>

                <Grid item xs={5} sm={4} md={3}>
                    <Typography variant='subtitle1'><b>{AccreditedCeActivity.label}: </b></Typography>
                </Grid>

                <Grid item xs={7} sm={8} md={9}>
                    <Typography variant='subtitle1'>{AccreditedCeActivity.informationText}</Typography>
                </Grid>

                <Grid item xs={5} sm={4} md={3}>
                    <Typography variant='subtitle1'><b>{WorkbasedLearning.label}: </b></Typography>
                </Grid>

                <Grid item xs={7} sm={8} md={9}>
                    <Typography variant='subtitle1'>{WorkbasedLearning.informationText}</Typography>
                </Grid>

                <Grid item xs={5} sm={4} md={3}>
                    <Typography variant='subtitle1'><b>{PracticeReview.label}: </b></Typography>
                </Grid>

                <Grid item xs={7} sm={8} md={9}>
                    <Typography variant='subtitle1'>{PracticeReview.informationText}</Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ width: '100%' }} component={Paper}>
            <Grid container rowSpacing={2} sx={{ marginLeft: "2%", marginTop: "0%" }}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'><i>* Please note detailed description of CPD activities
                        are available through PCS Guidelines on relevant scheme websites.</i></Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Please add your CPD activity details below.</Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ width: '100%' }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ marginLeft: "2%", marginTop: "0%" }}>

                <Grid item xs={12}>
                    <AppDatePicker
                        control={control}
                        name={ActivityDate.name}
                        label={ActivityDate.label}
                        information_text={ActivityDate.informationText} />
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete
                        control={control}
                        name={Category.name}
                        label={Category.label}
                        items={MapCategoriesToAutocompleteItems(cpdCategories ?? [])}
                        information_text={Category.informationText} />
                </Grid>

                <Grid item xs={12}>
                    <AppAutocomplete
                        control={control}
                        name={ActivityType.name}
                        label={ActivityType.label}
                        items={filteredActivityTypes}
                        information_text={ActivityType.informationText} />
                </Grid>

                <Grid item xs={12}>
                    <AppCheckboxFieldWithLeftAlignedLabel
                        control={control}
                        name={Domains.name}
                        label={Domains.label}
                        options={MapDomainsToCheckboxItems(goodPracticeDomains ?? [])}
                        information_text={Domains.informationText} />
                </Grid>

                <Grid item xs={12}>
                    <AppSlider control={control}
                        label={CreditsClaiming.label}
                        name={CreditsClaiming.name}
                        defaultValue={0} step={0.5} min={0} max={40}
                        information_text={CreditsClaiming.informationText} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control}
                        name={Description.name}
                        label={Description.label}
                        information_text_with_textarea={Description.informationText} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control}
                        name={DevelopmentPurpose.name}
                        label={DevelopmentPurpose.label}
                        information_text_with_textarea={DevelopmentPurpose.informationText} />
                </Grid>

                <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" type='submit' sx={{ mt: 3, ml: 1 }}
                        onClick={handleSubmit(submitForm)}>Submit</Button>
                </Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}