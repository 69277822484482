import { Text, View, StyleSheet, Image, Line, Svg } from '@react-pdf/renderer';
import { useState, useEffect } from 'react';
import { SchemEnum } from '../../app/enums/schemEnum';
import { PCSDetailsDto } from '../../app/models/pcsEnrolment/pcsDetailsDto';
import { Scheme } from '../../app/models/staticData/scheme';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        textAlign: 'left',
        height: 'auto',
        fontSize: 9,
        alignItems: 'center'
    },
    pcsHeaderLeft: {
        width: '80%',
        fontSize: 18,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold'
    },
    logo: {
        width: '30%'
    }
});

interface Props {
    registrationDetails: PCSDetailsDto | null;
    schemes: Scheme[] | null;
}

const ReceiptHeader = ({ registrationDetails, schemes }: Props) => {
    const [receiptLogo, setReceiptLogo] = useState<string>('');
    const [receiptLogoDesign, setReceiptLogoDesign] = useState({});

    useEffect(() => {
        if (registrationDetails !== null && schemes && schemes?.length > 0) {
            const schemeCode = schemes.find(x => x.id === registrationDetails.schemeId)?.code;
            let logoName = "";
            let logoDesign = {};

            if (schemeCode === SchemEnum.EmergencyMedicine) {
                logoName = "ICEMT-logo.jpg";
                logoDesign = { ...styles.logo, width: 65 };
            }
            else if (schemeCode === SchemEnum.Ophthalmology) {
                logoName = "Ophthalmology-logo.jpg";
                logoDesign = { ...styles.logo, width: 175 };
            }
            else if (schemeCode === SchemEnum.Radiology) {
                logoName = "Radiology-logo.png";
                logoDesign = { ...styles.logo, width: 175 };
            }
            else if (schemeCode === SchemEnum.SportsAndExerciseMedicine) {
                logoName = "FSEM-Trio-logo.jpg";
                logoDesign = { ...styles.logo, width: 300 };
            }
            else if (schemeCode === SchemEnum.Surgery) {
                logoName = "rcsi-logo-png.png";
                logoDesign = { ...styles.logo, width: 65 };
            }
            else {
                logoName = "rcsi-logo-png.png";
                logoDesign = { ...styles.logo, width: 65 };
            }

            setReceiptLogo(`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/${logoName}`);
            setReceiptLogoDesign(logoDesign);
        }
    }, [registrationDetails, schemes]);

    return (
        <>
            <View style={styles.row}>
                <Text style={styles.pcsHeaderLeft}>
                    Professional Competence Scheme
                </Text>

                <Image style={receiptLogoDesign} src={receiptLogo} />
            </View >

            <Svg height="5" width="525">
                <Line x1="0" y1="5" x2="525" y2="5" stroke="rgb(0,0,0)" />
            </Svg>
        </>
    )
};

export default ReceiptHeader;