import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import pdpFormModel from "./common/pdpFormModel";
import { pdpValidationSchema } from "./common/pdpValidationSchema";
import { CurrentWorkDetails } from "./CurrentWorkDetails";
import { Feedback } from "./Feedback";
import { PlannedActivities } from "./PlannedActivities";
import { PractitionerDetails } from "./PractitionerDetails";
import { ReviewerDetails } from "./ReviewerDetails";
import { PdpReviewerTabsEnum } from "../../app/enums/pdpReviewerTabsEnum";

const tabs = [PdpReviewerTabsEnum.MyPlannedActivities, PdpReviewerTabsEnum.ReviewerDetails, PdpReviewerTabsEnum.Feedback];
const { formField } = pdpFormModel;

export default function PdpReviewerForm() {
    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <PlannedActivities formField={formField} />
            case 1:
                return <ReviewerDetails formField={formField} />
            case 2:
                return <Feedback formField={formField} />
            default:
                throw new Error('Unknown step');
        }
    }

    const [activeStep, setActiveStep] = useState(0);

    const currentValidationSchema = pdpValidationSchema;

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(currentValidationSchema)
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveStep(newValue);
    };

    return <>
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4">
                    PDP Reviewer
                </Typography>

                <PractitionerDetails />
                <CurrentWorkDetails />

                <br /><br />

                <Tabs value={activeStep}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="primary tabs example">
                    {tabs.map((label: any, inedx: number) => (
                        <Tab key={label} value={inedx} label={label} />
                    ))}
                </Tabs>
                {getStepContent(activeStep)}
            </Paper>
        </FormProvider>
    </>
}
