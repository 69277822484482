import { Box, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { practiceOfMedicineOptions } from '../../app/utils/util';
import AppTextInput from '../../app/components/AppTextInput';
import registrationFormModel from '../../app/models/pcsEnrolment/registrationFormModel';
import AppCheckboxField from '../../app/components/AppCheckboxField';
import { CheckBoxItem } from '../../app/models/common/checkBoxItem';
import { MapRegistrationTypesToAutocompleteItems, MapSchemesToAutocompleteItems, MapSpecialitiesToAutocompleteItems } from '../../app/utils/convertStaticDataToDropdownItems';
import { useAppSelector } from '../../app/store/configureStore';
import { SchemEnum } from '../../app/enums/schemEnum';
import AppAutocomplete from '../../app/components/AppAutocomplete';
import { AutocompleteItem } from '../../app/models/common/autocompleteItem';

interface Prop {
  reenrol: boolean;
}

export default function RegistrationDetails({ reenrol }: Prop) {

  const methods = useFormContext();
  const { control, watch, reset, getValues, formState: { isDirty } } = methods;

  const { specialities, schemes, registrationTypes } = useAppSelector(state => state.ReferenceData);
  const { registrationDetails } = useAppSelector(state => state.pcs);

  const [filteredSpecialities, setFilteredSpecialities] = useState<AutocompleteItem[]>([]);

  const {
    registrationDetailsFormField: {
      IMCNumber,
      RegistrationType,
      Scheme,
      EngagedInPracticeOfMedicine,
      Specialty,
      SubSpecialty,
      UserConfirmation
    }
  } = registrationFormModel;

  const [userConfirmationOptions,] = useState<CheckBoxItem[]>([{
    id: UserConfirmation.name,
    value: UserConfirmation.label
  }]);

  useEffect(() => {
    if (registrationDetails && registrationDetails !== null && registrationDetails.schemeId) {
      filteredSpecialitiesByScheme(registrationDetails.schemeId);
    }
  }, [registrationDetails]);

  useEffect(() => {
    if (registrationDetails && registrationDetails !== null
      && schemes && schemes?.length > 0 && specialities && specialities?.length > 0
      && registrationTypes && registrationTypes?.length > 0
      && filteredSpecialities && filteredSpecialities?.length > 0
      && isDirty === false) {
      reset({
        [IMCNumber.name]: registrationDetails?.imcNumber ?? '',
        [RegistrationType.name]: registrationDetails?.registrationTypeId ?? '',
        [Scheme.name]: registrationDetails?.schemeId ?? '',
        [EngagedInPracticeOfMedicine.name]:
          registrationDetails?.engagedInPracticeOfMedicine === true ? '1' : '0',
        [Specialty.name]: registrationDetails?.specialtyId ?? '',
        [SubSpecialty.name]: registrationDetails?.subSpeciality ?? '',
        [UserConfirmation.name]: []
      });
    }
  }, [registrationDetails, schemes, specialities, filteredSpecialities, registrationTypes, reset]);

  useEffect(() => {
    watch((_, { name, type }) => {
      if (name === Scheme.name && type === 'change') {
        filteredSpecialitiesByScheme(getValues(Scheme.name));
      }
    });
  }, [Scheme.name, watch]);

  const filteredSpecialitiesByScheme = (schemeId: string) => {
    let specialitiesByScheme = specialities?.filter(x => x.schemeId === schemeId) ?? [];
    let specialtyList: AutocompleteItem[] = MapSpecialitiesToAutocompleteItems(specialitiesByScheme);
    setFilteredSpecialities(specialtyList);
  }

  return (
    <Box sx={{ minWidth: "100%" }} component={Paper}>
      <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

        <Grid item xs={12}>
          <AppTextInput control={control}
            name={IMCNumber.name}
            label={IMCNumber.label}
            disabled={reenrol === true} />
        </Grid>

        <Grid item xs={12}>
          <AppAutocomplete control={control} name={RegistrationType.name} label={RegistrationType.label}
            information_text={reenrol === true ? RegistrationType.reenrol_information_text
              : RegistrationType.enrol_information_text}
            items={MapRegistrationTypesToAutocompleteItems(registrationTypes ?? [])} />
        </Grid>

        <Grid item xs={12}>
          <AppAutocomplete control={control}
            name={Scheme.name}
            label={Scheme.label}
            items={MapSchemesToAutocompleteItems(schemes ?? [])}
            information_text={Scheme.information_text} />
        </Grid>

        <Grid item xs={12}>
          <AppAutocomplete control={control} name={Specialty.name} label={Specialty.label}
            items={filteredSpecialities.length > 0 ? filteredSpecialities : []}
            information_text={Specialty.information_text} />
        </Grid>

        {schemes !== null && schemes.find(x => x.id === getValues(Scheme.name))?.code === SchemEnum.Surgery &&
          <>
            <Grid item xs={12}>
              <AppTextInput control={control} name={SubSpecialty.name} label={SubSpecialty.label} />
            </Grid>
          </>}

        <Grid item xs={12}>
          <AppAutocomplete control={control} items={practiceOfMedicineOptions()}
            html_information_text={EngagedInPracticeOfMedicine.information_text}
            name={EngagedInPracticeOfMedicine.name} label={EngagedInPracticeOfMedicine.label} />
        </Grid>

        <Grid item xs={12}>
          <AppCheckboxField
            control={control}
            name={UserConfirmation.name}
            label={UserConfirmation.label}
            options={userConfirmationOptions} />
        </Grid>

        <Grid item xs={12}></Grid>

      </Grid>
    </Box>
  );
}
