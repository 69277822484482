import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { SummaryDto } from '../../app/models/summary/summaryDto';
import { PCSDetailsDto } from '../../app/models/pcsEnrolment/pcsDetailsDto';
import { RegistrationType } from '../../app/models/staticData/registrationType';
import { Scheme } from '../../app/models/staticData/scheme';
import { formatDateWithMonthName } from '../../app/utils/util';
import { PcsEnrolmentVerificationStatusEnum } from '../../app/enums/pcsEnrolmentVerificationStatusEnum';

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 5,
        fontSize: 9,
        fontFamily: 'Helvetica'
    },
    fieldLeft: {
        width: '30%'
    },
    fieldRight: {
        width: '40%'
    }
});

interface Props {
    summaryData: SummaryDto[][] | null;
    registrationDetails: PCSDetailsDto | null;
    schemes: Scheme[] | null;
    registrationTypes: RegistrationType[] | null;
    personName: string;
}

const ReceiptItems = ({ summaryData, registrationDetails, schemes, registrationTypes, personName }: Props) => {
    return (<>
        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Term
            </Text>

            <Text style={{ width: '10%' }}>
                01/05/{summaryData !== null && summaryData[summaryData.length - 1][0].text.split('-')[0].substring(0, 4)}
            </Text>

            <Text style={{ width: '3%', fontFamily: 'Helvetica-Bold', fontWeight: 'bold' }}>
                to
            </Text>

            <Text style={{ width: '10%' }}>
                30/04/{summaryData !== null && summaryData[0][0].text.split('-')[1].substring(1, 5)}
            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Name
            </Text>

            <Text style={styles.fieldRight}>
                {personName}
            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Scheme
            </Text>

            <Text style={styles.fieldRight}>
                {schemes?.find(x => x.id === registrationDetails?.schemeId)?.value}
            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Date of Enrolment
            </Text>

            <Text style={styles.fieldRight}>
                {registrationDetails?.enrolmentDate && formatDateWithMonthName(new Date(registrationDetails?.enrolmentDate))}
            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Date of Last Verification
            </Text>

            <Text style={styles.fieldRight}>
                {PcsEnrolmentVerificationStatusEnum.Unverified}
            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Medical Council Number
            </Text>

            <Text style={styles.fieldRight}>
                {registrationDetails?.imcNumber}
            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Registration Type
            </Text>

            <Text style={styles.fieldRight}>
                {registrationTypes?.find(x => x.id === registrationDetails?.registrationTypeId)?.value}
            </Text>
        </View>
    </>)
}

export default ReceiptItems;