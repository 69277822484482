import { Grid } from "@mui/material";

export function PlannedActivities(props: any) {
    const {
        formField: {
            External,
            WorkBasedLearning,
            PracticeReview
        }
    } = props;

    return <>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}></Grid>

            <Grid item xs={2}>
                {External.label}
            </Grid>

            <Grid item xs={10}>

            </Grid>

            <Grid item xs={2}>
                {WorkBasedLearning.label}
            </Grid>

            <Grid item xs={10}>

            </Grid>

            <Grid item xs={2}>
                {PracticeReview.label}
            </Grid>

            <Grid item xs={10}>

            </Grid>

        </Grid>
    </>
}