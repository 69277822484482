import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import PaymentSummary from "./PaymentSummary";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useMsal } from "@azure/msal-react";
import { IdTokenClaims } from "../../app/models/account/idTokenClaims";
import { useEffect, useState } from "react";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { canPayMembershipAsync } from "../../app/slices/pcsEnrolmentSlice";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";

export default function Payment() {
  const { instance } = useMsal();
  const userClaims = instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims;

  const { canPayMembership, canPayMembershipStatus } = useAppSelector(state => state.pcsEnrolment);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(canPayMembershipAsync());
  }, []);

  const [canRedirectToPaymentSummary, setCanRedirectToPaymentSummary] = useState(false);
  const [isCombined, setIsCombined] = useState(false);

  if (canPayMembershipStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <Box sx={{ flexGrow: 1 }} component={Paper}>
      <Grid container rowSpacing={4} sx={{ margin: "2%" }}>
        {canPayMembership === true && canRedirectToPaymentSummary === false &&
          <>
            <Grid item xs={12}>
              <Typography variant='h4'>Choose payment</Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginRight: "5%" }}>
              <i>
                If you are a Member or Fellow and practice in the Republic of Ireland,
                you can avail of a discounted Professional Competence Scheme enrolment
                fee <span style={{ color: 'red' }}>IF</span> you pay both PCS fee and Membership or Fellowship subscription
                together on or before 1 July of the current PCS year.
              </i>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => {
                setCanRedirectToPaymentSummary(true);
                setIsCombined(true);
              }}>
                Pay both PCS & Membership together
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => {
                setCanRedirectToPaymentSummary(true);
                setIsCombined(false);
              }}>
                Pay PCS only
              </Button>
            </Grid>
          </>
        }
        {(canPayMembership === false || canRedirectToPaymentSummary === true) &&
          <>
            <PaymentSummary isCombined={isCombined} />

            {userClaims?.extension_portalcrm_famIGS === false &&
              userClaims?.extension_portalcrm_famFeesDue === true &&
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => setCanRedirectToPaymentSummary(false)}>
                  Back to payment options
                </Button>
              </Grid>}
          </>
        }

        <Grid item xs={12}></Grid>

      </Grid>
    </Box >
  );
}
