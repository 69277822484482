import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        fontSize: 15
    },
    reportTitle: {
        fontSize: 14,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '10px'
    }
});

const ReceiptTitle = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>
            Statement of Participation
        </Text>
    </View>
);

export default ReceiptTitle;