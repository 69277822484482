import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GetColorCode } from "../../../../app/utils/util";
import { useEffect, useState } from "react";
import React from "react";
import AppInformationText from "../../../../app/components/AppInformationText";
import { useAppSelector } from "../../../../app/store/configureStore";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    alignCenter: {
        textAlign: 'center'
    },
    gridItem: {
        textAlign: 'center',
        paddingTop: '16px'
    }
}));

export function ActivitiesAcrossDomains() {
    const { activitiesAcrossDomains } = useAppSelector(state => state.pcsPdp);
    const { currentYear } = useAppSelector(state => state.pcs);

    const classes = useStyles();

    const [uniqueYears, setUniqueYears] = useState<number[]>();
    const [hasActivityAcrossDomains, setHasActivityAcrossDomains] = useState<boolean>(false);

    useEffect(() => {
        if (activitiesAcrossDomains !== null && currentYear !== null && setUniqueYears !== null) {

            let years: number[] = [];

            for (let year = currentYear - 1; year >= currentYear - 3; year--) {
                if (activitiesAcrossDomains && activitiesAcrossDomains?.summaryData &&
                    activitiesAcrossDomains?.summaryData?.filter(x => x.year === year).length > 0) {
                    years.push(year);
                }
            }
            setUniqueYears(years);

            if (years.length > 0) {
                setHasActivityAcrossDomains(true);
            }
        }
    }, [currentYear, activitiesAcrossDomains]);

    const getActivity = (year: number, domainCode: string) => {
        let domainId = activitiesAcrossDomains?.domains?.find(x => x.code === domainCode)?.id;
        return activitiesAcrossDomains?.summaryData?.find(x => x.year === year && x.domainId === domainId);
    }

    const setColor = (value: number | undefined) => {
        if (value === 0) return GetColorCode("RED");
        return GetColorCode("WHITE");
    }

    return (
        <>
            {hasActivityAcrossDomains &&
                <div className={classes.root}>
                    <Box sx={{ minWidth: "100%" }}>
                        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                            <Grid item xs={7} md={3} sm={5}>
                                <Typography variant='h6'>Activities across domains</Typography>
                            </Grid>

                            <Grid item xs={1} md={1} sm={1} sx={{ marginTop: '-7px' }}>
                                <AppInformationText information_text={'A red highlighted cell indicates no credits have been achieved in this domain.'} placement={"top"} />
                            </Grid>

                            <Grid item xs={4} md={8} sm={6}></Grid>
                        </Grid>

                        <Grid container component={Paper} columns={18}>
                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>PCS Year</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Clinical Skills</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Collaborative & Teamwork</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Communication & Interpersonal Skills</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Management (including Self Management)</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Patient Safety & Quality of Patient Care</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Professionalism</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Relating to Patients</b>
                            </Grid>

                            <Grid item xs={2} className={classes.alignCenter}>
                                <b>Scholarship</b>
                            </Grid>
                        </Grid>

                        {uniqueYears && uniqueYears.map((year: number, index: number) => (
                            <React.Fragment key={index}>

                                <Grid container component={Paper} columns={18}
                                    sx={{
                                        width: '100%',
                                        minWidth: '1100px'
                                    }}>

                                    <Grid item xs={2}>
                                        <Grid className={classes.gridItem} sx={{
                                            color: 'primary.main'
                                        }}>
                                            {`${year} - ${year + 1}`}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "CS")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "CS")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "C&T")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "C&T")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "CIS")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "CIS")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "MGMT")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "MGMT")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "PSQPC")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "PSQPC")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid item className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "PFM")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "PFM")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid item className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "SCP")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "RP")?.total}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={2} sx={{ padding: 1 }}>
                                        <Grid item className={classes.gridItem} sx={{
                                            backgroundColor: setColor(getActivity(year, "SCP")?.total),
                                            textAlign: 'center',
                                            padding: 1
                                        }}>
                                            {getActivity(year, "SCP")?.total}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </React.Fragment>
                        ))
                        }
                    </Box>
                </div >
            }
        </>
    )
}