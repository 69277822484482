const registrationFormModel = {
  formId: 'RegistrationForm',
  registrationDetailsFormField: {
    IMCNumber: {
      name: 'IMCNumber',
      label: 'IMC number*',
      requiredErrorMsg: 'IMC number is required',
      typeError: 'IMC number is required with numeric value.'
    },
    RegistrationType: {
      name: 'RegistrationType',
      label: 'Registration type*',
      requiredErrorMsg: 'Registration type is required',
      enrol_information_text: 'Select appropriate Medical Council registration type from drop down list',
      reenrol_information_text: `Select appropriate Medical Council registration type from drop down list 
              (this may have changed from your previous enrolment).`
    },
    Scheme: {
      name: 'Scheme',
      label: 'Scheme*',
      requiredErrorMsg: 'Scheme is required',
      information_text: 'Please select appropriate PCS scheme from drop down list.'
    },
    EngagedInPracticeOfMedicine: {
      name: 'EngagedInPracticeOfMedicine',
      label: 'Engaged in practice of medicine*',
      requiredErrorMsg: 'Engaged in practice of medicine is required',
      information_text: <>
        Indicate whether you are engaged or not engaged in the practice of medicine for this
        registration period.
        <br></br><br></br>
        Engagement in the practice of medicine incorporates activities including:
        <ul>
          <li>Involvement in the act of diagnosing, treating, or managing patient illnesses
            and medical conditions</li>
          <li>Provision of medical advice</li>
          <li>Prescription of medications</li>
          <li>Performing of medical procedures</li>
          <li>Development of preventative programmes</li>
          <li>Conducting relevant medical education or research</li>
          <li>Taking any other actions which require medical knowledge and skills</li>
        </ul>

        If you have any queries, then please contact your Postgraduate Training Body or Further
        information on Professional Competence Guidelines can be found on relevant schemes website.
      </>
    },
    UserConfirmation: {
      name: 'UserConfirmation',
      label: 'I confirm that my engagement in practice of medicine status is correct. I wish to proceed with my application and will notifiy the PCS office of any changes to this status.*',
      requiredErrorMsg: 'User confirmation is required'
    },
    Specialty: {
      name: 'Specialty',
      label: 'Specialty*',
      requiredErrorMsg: 'Specialty is required',
      information_text: 'Please select the specialty you are currently working in.'
    },
    SubSpecialty: {
      name: 'SubSpecialty',
      label: 'Sub specialty',
      information_text: 'Please indicate sub-specialty (if relevant).'
    }
  },
  personalDetailsFormField: {
    Title: {
      name: 'Title',
      label: 'Title*',
      requiredErrorMsg: 'Title is required'
    },
    FirstName: {
      name: 'FirstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    LastName: {
      name: 'LastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required'
    },
    DOB: {
      name: 'DOB',
      label: 'DOB*',
      requiredErrorMsg: 'DOB is required',
      invalidErrorMsg: 'DOB is not valid',
      typeError: 'Invalid DOB.'
    },
    Gender: {
      name: 'Gender',
      label: 'Gender*',
      requiredErrorMsg: 'Gender is required'
    },
    Nationality: {
      name: 'Nationality',
      label: 'Nationality*',
      requiredErrorMsg: 'Nationality is required'
    },
    MobileNumber: {
      name: 'MobileNumber',
      label: 'Mobile number*',
      requiredErrorMsg: 'Mobile number is required',
      typeError: 'Mobile number is not valid'
    }
  },
  educationalDetailsFormField: {
    QualificationCountry: {
      name: 'QualificationCountry',
      label: 'Qualification country*',
      requiredErrorMsg: 'Qualification country is required',
      information_text: 'Country where you obtained your primary medical degree.'
    },
    PrimaryMedicalDegreeCollege: {
      name: 'PrimaryMedicalDegreeCollege',
      label: 'University or College (Primary medical) degree*',
      requiredErrorMsg: 'University or College (Primary medical) degree is required',
      information_text: 'University or College where you obtained your primary medical degree.'
    },
    OtherPrimaryMedicalDegreeCollege: {
      name: 'OtherPrimaryMedicalDegreeCollege',
      label: 'Other University or College (Primary medical) degree*',
      requiredErrorMsg: 'Other University or College (Primary medical) degree is required',
      maxStringLengthErrorMsg: 'Maximum 200 characters allowed'
    },
    QualificationYear: {
      name: 'QualificationYear',
      label: 'Qualification year*',
      requiredErrorMsg: 'Qualification year is required',
      information_text: 'Qualification year you obtained your Primary Medical Degree.'
    }
  },
  workDetailsFormField: {
    StartDate: {
      name: 'StartDate',
      label: 'Start date*',
      requiredErrorMsg: 'Start date is required'
    },
    PostLevel: {
      name: 'PostLevel',
      label: 'Post level*',
      requiredErrorMsg: 'Post level is required',
      information_text: 'Please select the level of your post from the drop down list.'
    },
    Country: {
      name: 'Country',
      label: 'Country*',
      requiredErrorMsg: 'Country is required'
    },
    NameOfEmployer: {
      name: 'NameOfEmployer',
      label: 'Name of employer*',
      requiredErrorMsg: 'Name of employer/ Other (Name of employer) is required'
    },
    OtherNameOfEmployer: {
      name: 'OtherNameOfEmployer',
      label: 'Other (Name of employer)*',
      requiredErrorMsg: 'Other (Name of employer) is required'
    },
    NameOfClinicalSupervisor: {
      name: 'NameOfClinicalSupervisor',
      label: 'Name of clinical supervisor (if applicable)'
    },
    JobTitle: {
      name: 'JobTitle',
      label: 'Job title*',
      requiredErrorMsg: 'Job title is required.'
    },
    CurrentUserRoles: {
      name: 'CurrentUserRoles'
    },
    UserRoles: {
      name: 'UserRoles'
    },
    IsThisYourCurrentPost: {
      name: 'IsThisYourCurrentPost',
      label: 'Is this your current post?',
      requiredErrorMsg: 'Your choice is required.'
    }
  },
  paymentDetailsFormField: {
    NameOnCard: {
      name: 'nameOnCard',
      label: 'Name on card',
      requiredErrorMsg: 'Name on card is required'
    },
    CardNumber: {
      name: 'cardNumber',
      label: 'Card number',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    },
    ExpiryDate: {
      name: 'expiryDate',
      label: 'Expiry date',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    CVV: {
      name: 'CVV',
      label: 'CVV',
      requiredErrorMsg: 'CVV is required',
      digitLengthErrorMessage: 'Must be exactly 3 digits',
      typeError: 'CVV must be a numeric value.',
      invalidErrorMsg: 'CVV is invalid (e.g. 357)'
    },
    SaveCard: {
      name: 'saveCard',
      label: 'Remember credit card details for next time'
    }
  }
};

export default registrationFormModel;