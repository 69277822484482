import { Text, StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        fontSize: 9,
        lineHeight: 1.5
    }
});

interface Props {
    isYearPartialyCompleted: boolean;
}

const ReceiptFooter = ({ isYearPartialyCompleted }: Props) => {
    return <>
        {isYearPartialyCompleted === true && <View style={styles.titleContainer}>
            <Text>
                * Partial Year Completed.
            </Text>
        </View>}

        <View style={styles.titleContainer}>
            <Text>
                Note: CPD Category requirements are displayed in brackets.
            </Text>
        </View>
    </>
}

export default ReceiptFooter;