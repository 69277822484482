import * as yup from 'yup';
import registrationFormModel from './registrationFormModel';
import { MESSAGE_NOT_A_VALUE } from '../../utils/constant';
import { pcsFilter } from '../../utils/util';

const phoneRegExp = /^(?:\d{8}(?:\d{2}(?:\d{2})?)?|\(\+?\d{2,3}\)\s?(?:\d{4}[\s*.-]?\d{4}|\d{3}[\s*.-]?\d{3}|\d{2}([\s*.-]?)\d{2}\1\d{2}(?:\1\d{2})?))$/;
const cardNumberRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

const {
  registrationDetailsFormField: {
    IMCNumber,
    RegistrationType,
    Scheme,
    EngagedInPracticeOfMedicine,
    UserConfirmation,
    Specialty
  },
  personalDetailsFormField: {
    Title,
    FirstName,
    LastName,
    DOB,
    Gender,
    Nationality,
    MobileNumber
  },
  educationalDetailsFormField: {
    QualificationCountry,
    PrimaryMedicalDegreeCollege,
    OtherPrimaryMedicalDegreeCollege,
    QualificationYear
  },
  workDetailsFormField: {
    StartDate,
    PostLevel,
    Country,
    NameOfEmployer,
    OtherNameOfEmployer,
    JobTitle,
    CurrentUserRoles
  },
  paymentDetailsFormField: {
    NameOnCard,
    CardNumber,
    ExpiryDate,
    CVV
  }
} = registrationFormModel;

const postLevelValidationSchema = yup.string().required(`${PostLevel.requiredErrorMsg}`)
  .when([CurrentUserRoles.name],
    ([currentUserRoles], schema) => {
      const userRoles = currentUserRoles as unknown as string[];
      if (pcsFilter(userRoles)) {
        return yup.string().required(`${PostLevel.requiredErrorMsg}`);
      }
      return yup.string().notRequired();
    });

export const WorkDetailsValidationSchema = yup.object().shape({
  [StartDate.name]: yup.string().required(`${StartDate.requiredErrorMsg}`),
  [PostLevel.name]: postLevelValidationSchema,
  [Country.name]: yup.string().required(`${Country.requiredErrorMsg}`),
  [NameOfEmployer.name]: yup.string().when([OtherNameOfEmployer.name], ([otherNameOfEmployer], schema) => {
    return schema.test(`${NameOfEmployer.name}`, `${NameOfEmployer.requiredErrorMsg}`, function (nameOfEmployer) {
      if (nameOfEmployer === MESSAGE_NOT_A_VALUE) {
        return true;
      }
      if (otherNameOfEmployer !== "" || nameOfEmployer !== "") {
        return true;
      }
      return false;
    })
  }),
  [JobTitle.name]: yup.string().required(`${JobTitle.requiredErrorMsg}`)
});


export const EducationalDetailsValidationSchema = yup.object().shape({
  [QualificationCountry.name]: yup.string().required(`${QualificationCountry.requiredErrorMsg}`),
  [PrimaryMedicalDegreeCollege.name]: yup.string().when([OtherPrimaryMedicalDegreeCollege.name], ([otherPrimaryMedicalDegreeCollege], schema) => {
    return schema.test(`${PrimaryMedicalDegreeCollege.name}`, `${PrimaryMedicalDegreeCollege.requiredErrorMsg}`, function (primaryMedicalDegreeCollege) {
      if (primaryMedicalDegreeCollege === MESSAGE_NOT_A_VALUE) {
        return true;
      }
      if (otherPrimaryMedicalDegreeCollege !== "" || primaryMedicalDegreeCollege !== "") {
        return true;
      }
      return false;
    });
  }),
  [QualificationYear.name]: yup.string().required(`${QualificationYear.requiredErrorMsg}`)
});

export const registrationValidationSchema = [
  yup.object().shape({
    [IMCNumber.name]: yup.number().required(`${IMCNumber.requiredErrorMsg}`).typeError(`${IMCNumber.typeError}`),
    [RegistrationType.name]: yup.string().required(`${RegistrationType.requiredErrorMsg}`),
    [Scheme.name]: yup.string().required(`${Scheme.requiredErrorMsg}`),
    [EngagedInPracticeOfMedicine.name]: yup.string().required(`${EngagedInPracticeOfMedicine.requiredErrorMsg}`),
    [Specialty.name]: yup.string().required(`${Specialty.requiredErrorMsg}`),
    [UserConfirmation.name]: yup.array().min(1, UserConfirmation.requiredErrorMsg).of(yup.string().required(UserConfirmation.requiredErrorMsg))
  }),
  yup.object({
    [Title.name]: yup.string().required(`${Title.requiredErrorMsg}`),
    [FirstName.name]: yup.string().required(`${FirstName.requiredErrorMsg}`),
    [LastName.name]: yup.string().required(`${LastName.requiredErrorMsg}`),
    [DOB.name]: yup.string().nullable().required(`${DOB.requiredErrorMsg}`),
    [Gender.name]: yup.string().required(`${Gender.requiredErrorMsg}`),
    [Nationality.name]: yup.string().required(`${Nationality.requiredErrorMsg}`),
    [MobileNumber.name]: yup.string().required(`${MobileNumber.requiredErrorMsg}`)
      .matches(phoneRegExp, `${MobileNumber.typeError}`)
  }),
  EducationalDetailsValidationSchema,
  WorkDetailsValidationSchema,
  yup.object({
    [NameOnCard.name]: yup.string().required(`${NameOnCard.requiredErrorMsg}`),
    [CardNumber.name]: yup.string()
      .required(`${CardNumber.requiredErrorMsg}`)
      .matches(cardNumberRegEx, CardNumber.invalidErrorMsg),
    [ExpiryDate.name]: yup.string().required(`${ExpiryDate.requiredErrorMsg}`),
    [CVV.name]: yup.string()
      .required(`${CVV.requiredErrorMsg}`)
      .test('len', `${CVV.invalidErrorMsg}`, (val: any) => val && val.length === 3)
  })
]
